import React, {FC, useEffect, useState} from "react";

import './style.css'
import {Alert, Button, notification, Select, Space, Table} from "antd";
import {RedoOutlined} from '@ant-design/icons';
import {ColumnsType} from "antd/es/table";
import {exportUpgradeTerminals, runUpgrade, upgradeRecord} from "../../services/upgrade/gp";
import {IUpgradeRecordItem} from "../../services/upgrade/types";

export const UpdateNewDefaultPrm: FC = () => {
    const upgradeOptions = [{
        label: 'gpCustomization.json',
        value: 1
    },
        {
            label: 'CSTASK-4704',
            value: 2
        }
    ]
    const upgradeTips = {
        1: '给没有gpCustomization.json文件的终端生成默认参数的文件',
        2: '任务链接：https://castlestech.atlassian.net/browse/CSTASK-4704'
    }
    const [disableRun, setDisableRun] = useState<boolean>(true)
    const [tableData, setTableData] = useState<IUpgradeRecordItem[]>([])
    const [upgradeOption, setUpgradeOption] = useState<number>(0)
    const columns: ColumnsType<IUpgradeRecordItem> = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Upgrade Type',
            dataIndex: 'name',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Created Time',
            dataIndex: 'createdAt',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button disabled={record.message.toLowerCase() !== 'success'} size={"small"}
                            onClick={() => exportRecord(record.id)}>Export</Button>
                </Space>
            ),
        },
    ];

    const exportRecord = (id: number) => {
        exportUpgradeTerminals(id).then(res => {
            if (res.status === 0) {
                const a = document.createElement('a');
                a.target = "_blank"
                a.href = res.data.url;
                a.click();
            } else {
                notification.error({message: res.msg})
            }
        })
    }
    const changeUpgradeOption = (value: number) => {
        setUpgradeOption(value)
        setDisableRun(false)
    }
    const executeUpgrade = () => {
        runUpgrade({upgradeOption: upgradeOption}).then(res => {
            if (res.status === 0) {
                notification.success({
                    message: "The script is being executed in the background. Please refresh the upgrade records to check the progress",
                    duration: 5
                })
                getUpgradeRecord()
            } else {
                notification.error({message: res.msg})
            }
        })
    }

    const getUpgradeRecord = () => {
        upgradeRecord().then(res => {
            if (res.status === 0) {
                setTableData(res.data.list)
            } else {
                notification.error({message: res.msg})
            }
        })
    }

    useEffect(function () {
        getUpgradeRecord()
    }, []);

    return (
        <div className="upgrade-container">
            <div>
                <Select className="upgrade-select" options={upgradeOptions} onChange={changeUpgradeOption} />
                <Button type="primary" style={{marginLeft: "20px", width: "100px"}} disabled={disableRun}
                        onClick={executeUpgrade}>Run</Button>
            </div>
            {
                upgradeTips[upgradeOption] ? <Alert message={upgradeTips[upgradeOption]} type="info" showIcon /> : null
            }
            <div className="upgrade-table-title">
                <div className="upgrade-table-text">Executed records</div>
                <div style={{marginLeft: "30px"}}>
                    <Button type="primary" shape="circle" size="small" title="Refresh executed records"
                            icon={<RedoOutlined />} onClick={() => getUpgradeRecord()} />
                </div>
            </div>

            <Table columns={columns} dataSource={tableData} size={"small"} rowKey={"id"} />
        </div>
    );
};
